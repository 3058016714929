import {
  AppBar,
  Box,
  Checkbox,
  Dialog,
  TextField,
  Toolbar,
  Typography,

} from "@material-ui/core";

import * as XLSX from "xlsx";
import React from "react";
import {
  applyFilterOnData,
  checkFileValidation,
  downloadExcelFile,
  generatePDFFromHtml,
  IsSectionVisible,
  renameKey,
  UpdateColumnWithConfiguration,
} from "../../../utils/helper";
import PageHeader from "../../common/PageHeader";
import GridTable from "../../common/Table/Table";
import "../../../public/css/style.less";
import {
  apiBatchRequest,
  apiPost,
  apiPut,
  apiRequestNoLoadder,
  CancelRequest,
  RenewCancelToken,
  getSpsAPiRequest,
  spsApiPost,
  uploadSpsAPiRequest,
  uploadSpsMetadataAPiRequest,
  apiRequest,
  apiPostNoLoader,
} from "../../../utils/api_service";
import ReportButtons from "../../../utils/ReportButtons";
import { API_URL,getCurrentEnvironment } from "../../../constants/api";
import { industrialBuildingColumns } from "../../../constants/MockData";
import {
  FormControlLabel,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core";
import { ShowToast, toastType } from "../../../utils/toast-service";
import commonLabels from "../../../constants/messageConstants";
import PageFilter from "../../common/PageFilter";
import { controlType } from "../../../constants/common";
import EditBuilding from "./EditBuilding";
import camelcaseKeys from "camelcase-keys";
import SearchBar from "../../common/AutoSearch/SearchBar";
import SettingScreenDialog from "../../common/SettingScreenDialog/SettingScreenDialog";
import { downloadExcelReportByAPI, generateString, orderKey, setDownloadingStatus } from "../../../utils/generic";
import { SECTIONS } from "../../../constants/appConstants";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { cloneDeep } from "lodash";
import ConfirmDialog from "../../common/Dailogbox/confirmDailog";
import DownloadTemplate from "../../common/ExportImport/DownloadTemplate";
import { Autocomplete } from "@material-ui/lab";
import { getSPSTokenData, setSPSToken } from "../../../constants/constants";
import { getSharePointUrl } from "../../../constants/sharepointApi";
import { PaginatedGridTable } from "../../common/Table/PaginatedTable";


const uploadColumns = [
  {
    key: "buildingName",
    name: "Building",
  },
  {
    key: "parkingRatio",
    name: "Parking Ratio",
  },
  {
    key: "buildingClass",
    name: "Class",
  },
  {
    key: "market",
    name: "Market",
  },
  {
    key: "subMarket",
    name: "Sub Market",
  },
  {
    key: "sprinklerType",
    name: "Sprinkler",
  },
  {
    key: "coStarPropId",
    name: "CoStar ID",
  },
  {
    key: "rsf",
    name: "RBA",
  },
  {
    key: "percentLeased",
    name: "Leased(%)",
  },
  {
    key: "owner",
    name: "Owner",
  },
];

const columnWidths = [
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
];
export default class Building extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openDialog:false,
      openConfirmDialog:false,
      downloadFileUrl: null,
      selectedFile: null,
      selectedFileName: null,
      selectedFileSize: null,
      breakCondition: false,
      listOfColumns: [],
      listOfCompColumns: [],
      getImportfileData: [],
      listOfDbColumn: [],
      listOfHeader: [],
      lstRequiredField: [],
      uKey: null,
      uFile: null,
      selectedValue: null,
      selectedValueId: -1,
      selectedMarketId: this.props.globalMarketId,
      reloadTable: false,
      subMarketFilteredData: [],
      subMarketData: [],
      openFilter: false,
      isInAddMode: false,
      buildingSummaryData: [],
      responseBuildingData: [],
      configuration: [],
      buildingMasterData: [],
      companyMasterDetails: [],
    //  propertyFirmMaster: [],
      getSubMarketMaster: [],
      getSprinklerMaster: [],
      selectedEditRowId: undefined,
      detailView: false,
      ViewTitle: undefined,
      actionType: undefined,
      getBuildingMaster: [],
      getMarketMaster: [],
      searchInput: "",
      filterSelectedData: [],
      userConfiguration: [],
      openSlidingPanel: false,
      dbColumnList: [],
      rowsPerPage: 20,
      listOfColumns: [],
      enableFilterButton: false,
      openaddform: false,
      selectedBuilding: [],
      propertyOfNonInterest: [],
      responsePropertyOfNonInterest: [],
      headerData: {
        selectAll: false,
      },
      searchInputPopup: "",
      isConfirmPopupOpen: false,
      confirmationMessage: "",
      pageNumber:0,
      pageSize:10,
      bldgId:"",
      smId:"",
      bcId:"",
      cId:"",
      sprId:"",
      propertyNameAddrCostar:[],
      getSubmarketClusterMaster:[],
      poiPageNumber:0,
      poiPageSize:10,
    };
  }

  onRestoreToDefault = (config) => {
    let param = config;
    apiPost({
      url: API_URL.Master.addUserConfiguration,
      params: { restoreDefault: 1 },
      postBody: param,
    })
      .then(({ data: response }) => {
        if (response.success === true) {
          this.setState({
            reloadTable: !this.state.reloadTable,
            openSlidingPanel: false,
          });
          // this.forceUpdate();
          this.getUserConfigurationBySectionId();
          ShowToast(commonLabels.settingRestored, toastType.SUCCESS, 5000, 500);
          this.getRequiredData(this.state.selectedMarketId);
        } else {
          ShowToast(
            commonLabels.settingNotRestored,
            toastType.error,
            5000,
            500
          );
        }
      })
      .catch(() => {
        ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
      });
  };

  onSettingChange = (config, pageSize) => {
    let param = [];
    if (config.length > 0) {
      param = config.filter((m) => {
        if (pageSize != 0) m.rowPerPage = pageSize;
        return m;
      });
    } else {
      param = this.state.userConfiguration.filter((m) => {
        if (m.dataFormat == "MultiText") {
          m.minTag = 1;
          m.tagCount = true;
        }
        if (m.dataFormat == "Number") {
          m.headcellClass = "headcell-number-text";
        }
        if (
          m.sectionId == 11 &&
          m.isVisible == true &&
          m.key == "buildingName"
        ) {
          m.renderComponent = "renderActionColumn";
          // this.setState({
          if (pageSize != 0) m.rowPerPage = pageSize;
          //pageSize = m.rowPerPage;
          //reloadTable:!this.state.reloadTable,
          //});
          return m;
        }
      });
    }
    apiPost({
      url: API_URL.Master.addUserConfiguration,
      params: {},
      postBody: param,
    })
      .then(({ data: response }) => {
        if (response.success === true) {
          this.setState({
            // userConfiguration: camelcaseKeys(response.data),
            // rowsPerPage: response.data[0].rowPerPage,
            reloadTable: !this.state.reloadTable,
            openSlidingPanel: false,
          });
          // this.forceUpdate();
          this.getUserConfigurationBySectionId();
          ShowToast(commonLabels.settingUpdated, toastType.SUCCESS, 5000, 500);
          this.getRequiredData(this.state.selectedMarketId);
        } else {
          ShowToast(commonLabels.settingNotUpdated, toastType.error, 5000, 500);
        }
      })
      .catch(() => {
        ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
      });
  };
  componentDidMount() {
    RenewCancelToken();
    this.getRequiredData(this.state.selectedMarketId);
    //this.getRequiredDataWithoutLoader();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.globalMarketId !== this.state.selectedMarketId) {
      this.getRequiredData(nextProps.globalMarketId);
      this.setState({ selectedMarketId: nextProps.globalMarketId });
    }
  }

  componentWillUnmount() {
    CancelRequest();
  }

  getUserConfigurationBySectionId = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.GetUserConfigurations,
        key: "userConfiguration",
        params: { sectionId: 11 },
		fromCache: true,
        duration: 60,
        clearCache: true,
      },
    ])
      .then((userConfiguration) => {
        this._asyncRequest = null;
        if (userConfiguration.userConfiguration.success == true) {
          this.setState({
            userConfiguration:
              camelcaseKeys(userConfiguration.userConfiguration.data).sort(
                (a, b) => (a.attributeSortOrder > b.attributeSortOrder ? 1 : -1)
              ) || [],
            rowsPerPage: userConfiguration.userConfiguration.data[2].rowPerPage,
            //reloadRequired:true,
            reloadTable: !this.state.reloadTable,
            listOfColumns: userConfiguration.userConfiguration.data.filter(
              (m) => {
                if (m.isVisible == true) return m.key;
              }
            ),
          });
          this.forceUpdate();
          //this.child.setRowsPerPage(this.state.rowsPerPage);
        // this.childRef.current.handleChangeRowsPerPage(this.state.rowsPerPage);
        } else {
          ShowToast(
            userConfiguration.userConfiguration.message,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  apiRequestWithoutLoadder() {
	apiRequestNoLoadder({
		url: API_URL.Master.getMarketSubmarket,
		key: "getMarketSubmarket",
		params: { businessLineId: 2, marketId: selectedMarketId },
		//fromCache: true,
		//duration: 60,
	  }).then((getMarketSubmarket) => {
		this._asyncRequest = null;
		if (getMarketSubmarket.market.success == true) {
		  this.setState({
			getMarketMaster: camelcaseKeys(getMarketSubmarket.market.data) || [],
			getSubMarketMaster: camelcaseKeys(getMarketSubmarket.submarket.data) || [],
          subMarketData: camelcaseKeys(getMarketSubmarket.submarket.data) || [],
          getSubmarketClusterMaster: camelcaseKeys(getMarketSubmarket.submarketCluster.data) || [],
		  });
		  this.forceUpdate();
		} else {
		  ShowToast("Error", toastType.ERROR, 5000, 500, "Server error");
		}
	  });

	  apiRequestNoLoadder({
		url: API_URL.Master.industrialBuildingMasterData,
		key: "getBuildingMaster",
		params: { businessLineId: 2, marketId: this.state.selectedMarketId },
		//fromCache: true,
		//duration: 60,
	  }).then((getBuildingMaster) => {
		this._asyncRequest = null;
		if (getBuildingMaster.success == true) {
		  this.setState({
			getBuildingMaster: camelcaseKeys(getBuildingMaster.data) || [],
		  });
		  this.forceUpdate();
		} else {
		  ShowToast("Error", toastType.ERROR, 5000, 500, "Server error");
		}
	  });
  }

  getRequiredDataWithoutLoader = (selectedMarketId) => {
    this._asyncRequest = apiBatchRequest([
    //  {
    //    url: API_URL.Master.buildingMasterData,
    //    key: "buildingMasterData",
    //    params: { businessLineId: 2 },
    //  },
      {
        url: API_URL.Master.companyMasterDetails,
        key: "companyMasterDetails",
        params: { businessLineId: 2 },
      },
    //  {
    //    url: API_URL.Master.industrialBuildingPropertyFirmData,
    //    key: "propertyFirmMaster",
    //    params: { businessLineId: 2 },
    //  },

    //  {
    //    url: API_URL.Master.getSubMarketMaster,
    //    key: "getSubMarketMaster",
    //    params: { businessLineId: 2, marketId: selectedMarketId },
    //  },
    //  {
    //    url: API_URL.Master.industrialBuildingMasterData,
    //    key: "getBuildingMaster",
    //    params: { businessLineId: 2, marketId: selectedMarketId },
    //  },
    //  {
    //    url: API_URL.Master.getMarketMaster,
    //    key: "getMarketMaster",
    //    params: { businessLineId: 2, marketId: selectedMarketId },
    //  },
	
    //  {
    //    url: API_URL.Master.industrialBuildingSprinklerData,
    //    key: "getSprinklerMaster",
    //  },
	{
        url: API_URL.Master.getIndustrialBuildingMasters,
        key: "getIndustrialBuildingMasters",
        params: { businessLineId: 2 },
      },
	  {
        url: API_URL.Master.getMarketSubmarket,
        key: "getMarketSubmarket",
        params: { businessLineId: 2, marketId: selectedMarketId },
        //fromCache: true,
        //duration: 60,
        //clearCache: cache,
      },
    ])
      .then((buildingSummaryData) => {
        this._asyncRequest = null;
        if (buildingSummaryData.companyMasterDetails.success == true) {
          this.setState({
            buildingMasterData:
              camelcaseKeys(buildingSummaryData.getIndustrialBuildingMasters.buildingClasses) || [],
            companyMasterDetails:
              camelcaseKeys(buildingSummaryData.companyMasterDetails.data) ||
              [],
            //propertyFirmMaster:
            //  camelcaseKeys(buildingSummaryData.propertyFirmMaster.data) || [],
            getSubMarketMaster:
              camelcaseKeys(buildingSummaryData.getMarketSubmarket.submarket.data) || [],
            subMarketData:
              camelcaseKeys(buildingSummaryData.getMarketSubmarket.submarket.data) || [],
            getSprinklerMaster:
              camelcaseKeys(buildingSummaryData.getIndustrialBuildingMasters.sprinklerTypes) || [],
            //getBuildingMaster:
            //  camelcaseKeys(buildingSummaryData.getBuildingMaster.data) || [],
            getMarketMaster:
              camelcaseKeys(buildingSummaryData.getMarketSubmarket.market.data) || [],
              getSubmarketClusterMaster: camelcaseKeys(buildingSummaryData.getMarketSubmarket.submarketCluster.data) || [],
            enableFilterButton: true,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            buildingSummaryData.buildingSummaryData.message,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  getRequiredData = (selectedMarketId) => {
	if (selectedMarketId != "") {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialBuilding.industrialBuildingSummaryData,
        key: "buildingSummaryData",
        params: { marketId: selectedMarketId, 
          buildingId: this.state.bldgId,
          submarketId: this.state.smId,
          buildingClassId: this.state.bcId, 
          companyId:this.state.cId,
          sprinklerId:this.state.sprId,
          pageNumber: this.state.pageNumber + 1,
          pageSize: this.state.rowsPerPage },
      },
      {
        url: API_URL.Master.GetUserConfigurations,
        key: "userConfiguration",
        params: { sectionId: 11 },
		fromCache: true,
          duration: 60,
      },
      {
        url: API_URL.Master.getBuildingNameAddressCostarMaster,
        key: "propertyNameAddrCostar",
        params: { businessLineId :2,
          searchText:null, marketId: selectedMarketId},		
      },

    ])
      .then((buildingSummaryData) => {
        this._asyncRequest = null;
        if (buildingSummaryData.buildingSummaryData.success == true) {
          this.getRequiredDataWithoutLoader(selectedMarketId);
          this.setState({
            buildingSummaryData:
              camelcaseKeys(buildingSummaryData.buildingSummaryData.data) || [],
            responseBuildingData: _.cloneDeep(
              camelcaseKeys(buildingSummaryData.buildingSummaryData.data) || []
            ),

            configuration:
              camelcaseKeys(
                buildingSummaryData.buildingSummaryData.configuration
              ) || [],
            userConfiguration: camelcaseKeys(
              buildingSummaryData.userConfiguration.data != null
                ? buildingSummaryData.userConfiguration.data
                : JSON.parse(
                    window.sessionStorage.getItem("userConfigurations")
                  )
            ),
            listOfColumns: buildingSummaryData.userConfiguration.data.filter(
              (m) => {
                if (m.isVisible == true) return m.key;
              }
            ),
            rowsPerPage:
              buildingSummaryData.userConfiguration.data[2].rowPerPage,
              propertyNameAddrCostar: camelcaseKeys(buildingSummaryData.propertyNameAddrCostar.data) || [],
          });
          //  this.getRequiredDataWithoutLoader();
          this.forceUpdate();
        } else {
          ShowToast(
            buildingSummaryData.buildingSummaryData.message,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
	}
  };

  reloadGridData = (selectedMarketId) => {
    if (selectedMarketId != "") {
      this._asyncRequest = apiBatchRequest([
        {
          url: API_URL.IndustrialBuilding.industrialBuildingSummaryData,
          key: "buildingSummaryData",
          params: { marketId: selectedMarketId, 
            buildingId: this.state.bldgId,
            submarketId: this.state.smId,
            buildingClassId: this.state.bcId, 
            companyId:this.state.cId,
            sprinklerId:this.state.sprId,
            pageNumber: this.state.pageNumber + 1,
            pageSize: this.state.rowsPerPage },
        },
        
      ])
        .then((buildingSummaryData) => {
          this._asyncRequest = null;
          if (buildingSummaryData.buildingSummaryData.success == true) {
            
            this.setState({
              buildingSummaryData:
                camelcaseKeys(buildingSummaryData.buildingSummaryData.data) || [],
              responseBuildingData: _.cloneDeep(camelcaseKeys(buildingSummaryData.buildingSummaryData.data) || []),
            });
            //  this.getRequiredDataWithoutLoader();
            this.forceUpdate();
          } else {
            ShowToast(
              buildingSummaryData.buildingSummaryData.message,
              toastType.ERROR,
              5000,
              500,
              "Server error"
            );
          }
        })
        .catch(() => {
          this._asyncRequest = null;
        });
    }
    };

  renderActionColumn = (cellInfo) => {
    return (
      <>
        <Tooltip title="View">
          <a
            className="default-text"
            aria-label={"refreshData"}
            size="small"
            onClick={() => {
              this.setState({
                isInAddMode: false,
              });
              this.onRowClick(cellInfo);
            }}
          >
            <span className="default-text span-link">
              {cellInfo.buildingName ? cellInfo.buildingName : "---"}
            </span>
          </a>
        </Tooltip>
      </>
    );
  };
  onRowClick = (data) => {
    this.setState({
      detailView: true,
      selectedEditRowId: data.buildingId,
      ViewTitle: "Edit",
      actionType: "Edit",
    });
  };

  onFilterChange = (selectedData) => {
    if (selectedData.length > 0) {
      //console.log("filter selected length" + selectedData[0].selected.length);
      //console.log("filter key" + selectedData[0].key);
      let smId = "";
      let bcId = "";
      let bId = "";
      let cId = "";
      let sId = "";
      for (var i = 0; i < selectedData.length; i++) 
      { 
        if(selectedData[i].key === "subMarket")
        {            
          for(var j = 0; j < selectedData[i].selected.length; j++)
          {
            smId += selectedData[i].selected[j].subMarketId + '|';
          }          
        }
        if(selectedData[i].key === "buildingClass")
        {            
          for(var j = 0; j < selectedData[i].selected.length; j++)
          {
            bcId += selectedData[i].selected[j].buildingClassId + '|';
          }          
        }
        if(selectedData[i].key === "buildingName")
        {            
          for(var j = 0; j < selectedData[i].selected.length; j++)
          {
            bId += selectedData[i].selected[j].buildingId + '|';
          }          
        }
        if(selectedData[i].key === "companyName")
        {            
          for(var j = 0; j < selectedData[i].selected.length; j++)
          {
            cId += selectedData[i].selected[j].companyId + '|';
          }          
        }
        if(selectedData[i].key === "sprinklerType")
        {            
          for(var j = 0; j < selectedData[i].selected.length; j++)
          {
            sId += selectedData[i].selected[j].sprinklerTypeId + '|';
          }          
        }

        ""
      }
      let data = [];
      data.push(selectedData);

      this.setState({
        filterSelectedData: data,
        bldgId:bId,
        smId:smId,
        bcId:bcId,
        cId:cId,
        sprId:sId,
        pageNumber:0
      }, () => {
        this.forceUpdate();
        this.reloadGridData(this.state.selectedMarketId);
      });
    }
    else {
      this.setState({
        filterSelectedData: null,
        bldgId:"",
        smId:"",
        bcId:"",
        cId:"",
        sprId:"",
        pageNumber:0
      }, () => {
        this.forceUpdate();
        this.reloadGridData(this.state.selectedMarketId);
      });
    } 
  };

  filterData(buildingSummaryData, selectedData) {
    const filteredData = applyFilterOnData(
      [...buildingSummaryData],
      selectedData
    );
    this.setState(
      {
        buildingSummaryData: filteredData,
        selectedData: selectedData,
        reloadTable: !this.state.reloadTable,
      },
      () => {
        this.forceUpdate();
      }
    );
  }
  onBackClick = () => {
    this.setState({ detailView: false, selectedEditRowId: undefined });
  };

  onCancelClickAfterPopupAdd = () => {
    this.reloadPropertyCompanyData();
  };

  onPopupBackClick = () => {
    this.setState({ openaddform: false });
  };

  enableFilter = () => {
    let openFilter = this.state.openFilter;
    this.setState({ openFilter: !openFilter });
  };

  handlePrintClick = () => {
    this.setState(
      {
        printPage: true,
      },
      window.print()
    );
  };

  exportPDF = () => {
    downloadExcelReportByAPI(
      API_URL.SSRSExport.IndustrialProperty,
      "PropertiesExport.pdf",
      this.state.selectedData,
      this.state.selectedMarketId,
      "application/pdf",
      "industrialProperty"
    );
  };

  // handleChange = (event) => {
  //   this.setState({ searchInput: event.target.value }, () => {
  //     this.globalSearch();
  //   });
  // };

  onSearchInputChange = (e,value) => {    
  }

  resetAppliedFilter = () => {
    document.getElementById("tags-standard").value = '';
    this.setState({
      searchInput:"",
      filterSelectedData: null,
      bldgId:"",
      smId:"",
      bcId:"",
      cId:"",
      sprId:"",
      pageNumber:0      
    }, () => {
      this.forceUpdate();
      this.reloadGridData(this.state.selectedMarketId);
    });

  }

  handleSearchChange = (e, value, name) => {
   
    let buildingId=value != null && value != undefined ? value.buildingId :null
    let buildingName=value != null && value != undefined ? value.buildingName :null
    this.setState({
      searchInput: buildingName
    }, () => {
      this.handleChange(e,buildingId,1);
    });
    
    
  };
  handleChange = (event,buildingId,bit) => { 
     
    this.setState({
      filterSelectedData: null,
      bldgId:buildingId,
      smId:"",
      bcId:"",
      cId:"",
      sprId:"",
      pageNumber:0
    }, () => {
      this.forceUpdate();
      this.reloadGridData(this.state.selectedMarketId);
    });

  };

  handlePopupChange = (event) => {
    this.setState({ searchInputPopup: event.target.value,poiPageNumber: 0 }, () => {
      this.onPaginatePOIClick();
    });
  };

  globalSearch = () => {
    alert('global');
    let { searchInput, buildingSummaryData } = this.state;
    let searchData = this.state.responseBuildingData;
    let filteredData = searchData.filter((value) => {
      return (
        (this.handleCheck("coStarPropId") && value.coStarPropId
          ? value.coStarPropId
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("parkingRatio") && value.parkingRatio
          ? value.parkingRatio
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("buildingClass") && value.buildingClass
          ? value.buildingClass
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("buildingName") && value.buildingName
          ? value.buildingName.toLowerCase().includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("owner") && value.owner
          ? value.owner.toLowerCase().includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("market") && value.market
          ? value.market.toLowerCase().includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("percentLeased") && value.percentLeased
          ? value.percentLeased
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("subMarket") && value.subMarket
          ? value.subMarket.toLowerCase().includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("rsf") && value.rsf
          ? value.rsf
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("parkingRatio") && value.parkingRatio
          ? value.parkingRatio
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("agentName") && value.agentName
          ? value.agentName
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("buildingPark") && value.buildingPark
          ? value.buildingPark
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("ceilingHeight") && value.ceilingHeight
          ? value.ceilingHeight
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("daysOnMarket") && value.daysOnMarket
          ? value.daysOnMarket
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("developer") && value.developer
          ? value.developer
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("distributionClass") && value.distributionClass
          ? value.distributionClass
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("landArea") && value.landArea
          ? value.landArea
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("latitude") && value.latitude
          ? value.latitude
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("longitude") && value.longitude
          ? value.longitude
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("management") && value.management
          ? value.management
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("materialType") && value.materialType
          ? value.materialType
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("numberOfLoadingDocks") && value.numberOfLoadingDocks
          ? value.numberOfLoadingDocks
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("sprinklerType") && value.sprinklerType
          ? value.sprinklerType
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("yearBuilt") && value.yearBuilt
          ? value.yearBuilt
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("zip") && value.zip
          ? value.zip
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("add1") && value.add1
          ? value.add1
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("add2") && value.add2
          ? value.add2
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false) ||
        (this.handleCheck("buildingNotes") && value.buildingNotes
          ? value.buildingNotes
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          : false)
      );
    });

    if (searchInput.length == 0)
      this.setState({ buildingSummaryData: this.state.responseBuildingData });
    else this.setState({ buildingSummaryData: filteredData });
  };

  globalSearchPopup = () => {
    let { searchInputPopup, propertyOfNonInterest } = this.state;
    let searchData = this.state.responsePropertyOfNonInterest;
    let filteredData = searchData.filter((value) => {
      return (
        (this.handleCheck("buildingName") && value.buildingName
          ? value.buildingName
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("address") && value.address
          ? value.address
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("buildingClass") && value.buildingClass
          ? value.buildingClass
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("market") && value.market
          ? value.market
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("subMarket") && value.subMarket
          ? value.subMarket
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("coStarPropId") && value.coStarPropId
          ? value.coStarPropId
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("rsf") && value.rsf
          ? value.rsf
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("vacancy") && value.vacancy
          ? value.vacancy
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("companyName") && value.companyName
          ? value.companyName
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("add2") && value.add2
          ? value.add2
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("existingUc") && value.existingUc
          ? value.existingUc
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("buildingNotes") && value.buildingNotes
          ? value.buildingNotes
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("latitude") && value.latitude
          ? value.latitude
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("longitude") && value.longitude
          ? value.longitude
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("management") && value.management
          ? value.management
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("walkScore") && value.walkScore
          ? value.walkScore
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("ratio") && value.ratio
          ? value.ratio
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("leasing") && value.leasing
          ? value.leasing
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("owner") && value.owner
          ? value.owner
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("onMarket") && value.onMarket
          ? value.onMarket
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("isClient") && value.isClient
          ? value.isClient
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("development") && value.development
          ? value.development
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("reno") && value.reno
          ? value.reno
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("monthDelivering") && value.monthDelivering
          ? value.monthDelivering
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("since") && value.since
          ? value.since
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("floorPlate") && value.floorPlate
          ? value.floorPlate
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("yearBuilt") && value.yearBuilt
          ? value.yearBuilt
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false) ||
        (this.handleCheck("zip") && value.zip
          ? value.zip
              .toString()
              .toLowerCase()
              .includes(searchInputPopup.toLowerCase())
          : false)
      );
    });
    this.setState({ propertyOfNonInterest: filteredData });
  };

  onAddNewClick = () => {
    this.setState({
      isInAddMode: true,
      detailView: true,
      ViewTitle: "Add",
      actionType: "Add",
    });
  };

  onConvertToPOIClick = () => {
    //if(this.state.propertyOfNonInterest.length === 0){
      this.setState({
        poiPageNumber: 0 ,
        searchInputPopup: "",        
      });
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialBuilding.industrialGetPropertyOfNonInterest,
        key: "propertyOfNonInterest",
        params: { marketId: this.state.selectedMarketId, 
          searchText: "",
          pageNumber: this.state.poiPageNumber + 1,
          pageSize: this.state.rowsPerPage  
        },
      },
    ])
      .then((propertyOfNonInterest) => {
        this._asyncRequest = null;
        if (propertyOfNonInterest.propertyOfNonInterest.success == true) {
          this.setState(
            {
              propertyOfNonInterest:
                camelcaseKeys(
                  propertyOfNonInterest.propertyOfNonInterest.data
                ) || [],
              responsePropertyOfNonInterest: _.cloneDeep(
                camelcaseKeys(
                  propertyOfNonInterest.propertyOfNonInterest.data
                ) || []
              ),
            },
            () => {
              this.forceUpdate();
            }
          );
        } else {
          ShowToast(
            propertyOfNonInterest.propertyOfNonInterest.message,
            toastType.error,
            5000,
            500
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });

    this.setState({
      openaddform: true,
      selectedBuilding: []
      
    });
  };

  selectProperty = (e, cellInfo) => {
    let selectedBuilding = this.state.selectedBuilding;
    const headerData = this.state.headerData;
    const data = cloneDeep(
      this.state.propertyOfNonInterest.filter((x) => x.isStreamTracked == true)
    );
    if (e.target.checked) {
      //cellInfo.data.isStreamTracked = true;
      selectedBuilding.push(cellInfo);
    } else {
      selectedBuilding = selectedBuilding.filter(
        (x) => x.buildingId !== cellInfo.buildingId
      );
    }

    this.state.selectedBuilding.map((x) => {
      x.isStreamTracked = true;
      return x;
    });
    this.state.selectedBuilding.map((x) => {
      x.keepMarketStats = false;
      return x;
    });
    headerData.selectAll =
      (selectedBuilding || {}).length === (data || {}).length;
    this.setState(
      { selectedBuilding, headerData, reloadTable: !this.state.reloadTable },
      () => {
        this.forceUpdate();
      }
    );
  };

  renderPropertyOfInterestColumn = (row) => {
    const isChecked =
      this.state?.selectedBuilding?.find(
        (x) => x.buildingId === row.buildingId
      ) !== undefined || false;
    return (
      <>
        <Tooltip title={isChecked ? "Deselect Property" : "Select Property"}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={(e) => this.selectProperty(e, row)}
                name="Select Property"
                color="default"
                size="small"
                className={"white-checkbox"}
              />
            }
          />
        </Tooltip>
      </>
    );
  };

  handleTableHeaderEditChange = (value, fieldName) => {
    const { headerData, propertyOfNonInterest } = this.state;
    const selectProperty = propertyOfNonInterest.map((x) => {
      x.isStreamTracked = true;
      return x;
    });
    headerData[fieldName] = value;

    this.setState(
      {
        headerData,
        selectedBuilding: value ? selectProperty : [],
        reload: !this.state.reload,
      },
      () => {
        this.forceUpdate();
      }
    );
  };

  confirmSave = () => {
    if (this.state.selectedBuilding.length > 1) {
      this.setState({
        isConfirmPopupOpen: true,
        confirmationMessage:
          "Are you sure, you want to update '" +
          this.state.selectedBuilding.length +
          "' Properties?",
      });
    } else {
      if (this.state.selectedBuilding.length == 0) {
        ShowToast("Please select any property", toastType.ERROR, 5000, 500);
      } else {
        this.setState({
          isConfirmPopupOpen: true,
          confirmationMessage:
            "Are you sure, you want to update '" +
            this.state.selectedBuilding.length +
            "' Property?",
        });
      }
    }
  };

  onChangeKeepMarketStats = (e) => {
    if (e.target.checked) {
      this.state.selectedBuilding.map((x) => {
        x.keepMarketStats = true;
        return x;
      });
    } else {
      this.state.selectedBuilding.map((x) => {
        x.keepMarketStats = false;
        return x;
      });
    }
  };

  onInputChange = (filter, value) => {
    const params = {
      businessLineId: 2,
      searchText: value,
    };
	if(filter.key == 'buildingName'){
		apiRequestNoLoadder({
			url: API_URL.Master.industrialBuildingMasterData,
			params,
		  })
			.then(({ data: getBuildingMaster }) => {
			  this.setState(
				{
				  getBuildingMaster: camelcaseKeys(getBuildingMaster) || [],
				},
				() => {
				  this.forceUpdate();
				}
			  );
			})
			.catch((reason) => {
			  this._asyncRequest = null;
			  ShowToast(
				commonLabels.somethingWrong,
				toastType.ERROR,
				5000,
				500,
				"Server error"
			  );
			});
	}
	else{
    apiRequestNoLoadder({
      url: API_URL.Master.buildingOwnerMasterDetails,
      params,
    })
      .then(({ data: companyMasterDetails }) => {
        this.setState(
          {
            companyMasterDetails: camelcaseKeys(companyMasterDetails) || [],
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((reason) => {
        this._asyncRequest = null;
        ShowToast(
          commonLabels.somethingWrong,
          toastType.ERROR,
          5000,
          500,
          "Server error"
        );
      });
	}
  };

  onBuildingInputChange = (filter, value) => {
    const params = {
      businessLineId: 2,
      searchText: value,
    };
    apiRequestNoLoadder({
      url: API_URL.Master.industrialBuildingMasterData,
      params,
    })
      .then(({ data: getBuildingMaster }) => {
        this.setState(
          {
            getBuildingMaster: camelcaseKeys(getBuildingMaster) || [],
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((reason) => {
        this._asyncRequest = null;
        ShowToast(
          commonLabels.somethingWrong,
          toastType.ERROR,
          5000,
          500,
          "Server error"
        );
      });
  };

  onSaveChange = (value) => {
    if (value) {
      const buildingIds = this.state.selectedBuilding.map((m) => {
        return m.buildingId;
      });
      const keepMarketStats = this.state.selectedBuilding.map((m) => {
        return m.keepMarketStats;
      });
      const streamTracks = this.state.selectedBuilding.map((m) => {
        return m.isStreamTracked;
      });
      let postBodyParams = {
        BuildingId: buildingIds,
        IsStreamTracked: streamTracks,
        KeepMarketStats: keepMarketStats,
      };

      apiPut(
        API_URL.IndustrialBuilding.industrialUpdatePropertyOfNonInterest,
        postBodyParams,
        {}
      )
        .then(({ data: response }) => {
          if (response.success === true) {
            ShowToast(
              "Properties updated successfully",
              toastType.SUCCESS,
              5000,
              500
            );
            this.setState({
              openaddform: false,
              selectedBuilding: [],
            });
            this.getRequiredData(this.state.selectedMarketId);
          } else {
            ShowToast("Error", toastType.error, 5000, 500);
          }
        })
        .catch(() => {
          ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
        });
    } else {
      this.setState({ isConfirmPopupOpen: false });
      return;
    }
    this.setState({ isConfirmPopupOpen: false });
  };

  reloadPropertyCompanyData = () => {
    this._asyncRequest = apiBatchRequest([
    //  {
    //    url: API_URL.Master.industrialBuildingMasterData,
    //    key: "getBuildingMaster",
    //    params: { businessLineId: 2, marketId: this.state.selectedMarketId },
    //    fromCache: true,
    //    duration: 60,
    //    clearCache: true,
    //  },
    ]).then((companyMasterDetails) => {
      this._asyncRequest = null;
      if (companyMasterDetails.getBuildingMaster.success == true) {
        this.setState({
        //  getBuildingMaster:
        //    camelcaseKeys(companyMasterDetails.getBuildingMaster.data) || [],
        //    companyMasterDetails: [],
        });
        this.forceUpdate();
      } else {
        ShowToast("Error", toastType.ERROR, 5000, 500, "Server error");
      }
    });
  };

  reloadData = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialBuilding.industrialBuildingSummaryData,
        key: "buildingSummaryData",
        params: { marketId: this.state.selectedMarketId, 
          buildingId: this.state.bldgId,
          submarketId: this.state.smId,
          buildingClassId: this.state.bcId, 
          companyId:this.state.cId,
          sprinklerId:this.state.sprId,
          pageNumber: this.state.pageNumber + 1,
          pageSize: this.state.rowsPerPage },
      },
      {
        url: API_URL.Master.getBuildingNameAddressCostarMaster,
        key: "propertyNameAddrCostar",
        params: { businessLineId :2,
          searchText:null, marketId: this.state.selectedMarketId},		
      },
    ])
      .then((buildingSummaryData) => {
        this._asyncRequest = null;
        if (buildingSummaryData.buildingSummaryData.success == true) {
          this.setState({
            buildingSummaryData:
              camelcaseKeys(buildingSummaryData.buildingSummaryData.data) || [],
            responseBuildingData: _.cloneDeep(
              camelcaseKeys(buildingSummaryData.buildingSummaryData.data) || []
            ),
            configuration:
              camelcaseKeys(
                buildingSummaryData.buildingSummaryData.configuration
              ) || [],
              propertyNameAddrCostar: camelcaseKeys(buildingSummaryData.propertyNameAddrCostar.data) || [],
            detailView: false,
            selectedEditRowId: undefined,
            companyMasterDetails:[],
          });
          this.forceUpdate();
		      this.reloadPropertyCompanyData();
        } else {
          ShowToast(
            buildingSummaryData.buildingSummaryData.message,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  downloadReport = () => {
    downloadExcelReportByAPI(
      API_URL.SSRSExport.IndustrialProperty,
      "PropertiesExport.xls",
      this.state.selectedData,
      this.state.selectedMarketId,
      "application/vnd.ms-excel",
      "industrialProperty"
    );
  };

  resetFilters = () => {
    
    this.setState({
      filterSelectedData: null,
      bldgId:"",
      smId:"",
      bcId:"",
      cId:"",
      sprId:"",
      pageNumber:0
    }, () => {
      this.forceUpdate();
      this.reloadGridData(this.state.selectedMarketId);
    });
    
  };
  handleClickOpen = () => {
    this.setState({
      openSlidingPanel: true,
    });
  };

  handleClose = () => {
    this.setState({
      openSlidingPanel: false,
    });
  };
  handleCheck(val) {
    return this.state.listOfColumns.some((item) => item.key === val);
  }
  onCancel = () => {
    this.setState({
      openDialog: false,
    });
  };
  enableDownload = () => {
    this.setState({
      openDialog: true,
    });
  };
  downloadTemplate = () => {
    //downloadExcelFile('src//components//Dashboard//PropertyOwnersExport.xlsx','MarketStats_ImportTemplate');
    downloadExcelFile(
      "public//ImportTemplate//Property_BulkUpload_Industrial.xlsx",
      "Property_BulkUpload_Industrial"
    );
  };
  importFile = () => {
    this.setState({ isUpload: true, openDialog: false });
  };
  onFileChange = (event) => {
    // Update the state
    this.setState({
      selectedFile: event.target.files[0],
      selectedFileName: event.target.files[0].name,
      selectedFileSize: event.target.files[0].size,
    });
    this.getImportFileDetails();
  };
  getImportFileDetails = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.ImportExport.getImportfileData,
        key: "getImportfileData",
        params: { sectionId: 238},
        fromCache: true,
        duration: 60,
      },
    ])
      .then((getImportfileData) => {
        this._asyncRequest = null;
        if (getImportfileData.getImportfileData.success == true) {
          let temp = [];
          let dbColumn = [];
          let requiredColumn = [];
          getImportfileData.getImportfileData.data
            .sort((a, b) => (a.UIColumn > b.UIColumn ? 1 : -1))
            .filter((m) => {
              if (m.IsVisible == 1) {
                temp.push(m.UIColumn);
                dbColumn.push(m.DBColumn);
              }
              if (m.IsRequired == 1) {
                requiredColumn.push(m.DBColumn);
              }
            });
            // let importData= camelcaseKeys(getImportfileData.getImportfileData.data.filter((m)=>m.IsVisible == false )).sort(
            //   (a, b) => (a.uiColumn > b.uiColumn ? 1 : -1)
            // ) || [];
          this.setState(
            {
              getImportfileData:
                camelcaseKeys(getImportfileData.getImportfileData.data).sort(
                  (a, b) => (a.uiColumn > b.uiColumn ? 1 : -1)
                ) || [],

              listOfCompColumns: temp.sort(),
              listOfDbColumn: dbColumn,
              lstRequiredField: requiredColumn.sort(),
            },
            () => {
              this.forceUpdate();
            }
          );
        } else {
          ShowToast(
            "Something Went Wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };
  onFileUpload = () => {
    if (
      this.state.selectedFile == null ||
      this.state.selectedFile == undefined
    ) {
      ShowToast("Please select a file before upload.", toastType.ERROR, 5000);
    } else {
      const uploadingKey = generateString(6);
      // Create an object of formData
      setDownloadingStatus(
        uploadingKey,
        this.state.selectedFileName,
        "processing",
        "",
        "Processing import file"
      );
      const formData = new FormData();
      if (typeof FileReader !== "undefined") {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = (e) => {
            this.processExcel(
                  reader.result,
                  this.state.selectedFileName,
                  uploadingKey
                )
             
          };
          reader.readAsBinaryString(this.state.selectedFile);
        }
      }
      this.setState({ isUpload: false, openDialog: false, });
    }
  }; 
  processExcel = (data, uploadedFileName, uploadingKey) => {
    // this.setState({ upKey: uploadingKey, upfileName: uploadedFileName });
    this.setState({ breakCondition: false });
    let errorMessage = "";
    const workbook = XLSX.read(data, {
      type: "binary",
      cellDates: true,
      cellText: false,
    });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet],
      { defval: null, raw: false, dateNF: "mm/dd/yyyy hh:mm:ss" }
    );
    if (
      this.state.selectedValue == null ||
      this.state.selectedValue == undefined ||
      this.state.selectedValue == ""
    ) {
      this.setState({ breakCondition: true, selectedFile: null });
      errorMessage = "Please select market before import.";
      setDownloadingStatus(
        uploadingKey,
        uploadedFileName,
        "failed",
        errorMessage,
        "Validation Failure"
      );
      return false;
    }
    const formattedData = excelRows.filter((m) => {     
      return m;
    });
    let headers = [];
    if (formattedData.length == 0) {
      this.setState({ breakCondition: true, selectedFile: null });
      errorMessage =
        "The import file is empty. Please upload file with valid data";

      setDownloadingStatus(
        uploadingKey,
        uploadedFileName,
        "failed",
        errorMessage,
        "Validation Failure"
      );
      return;
    }

    formattedData.forEach(function (e, i) {
      // Iterate over the keys of object
      Object.keys(e).forEach(function (key) {
        // Copy the value
        var replacedKey = key.toString().trim();
        i == 0  && key != "Comments" ? headers.push(replacedKey) : null;
        if (key !== replacedKey) {
          e[replacedKey] = e[key];
          delete e[key];
        }
      });
    });
    let invalidObject=[];
    formattedData.forEach((m)=>{if((m.RSF == null || m.RSF == undefined )||
      (m.StreamBuildingClass == null || m.StreamBuildingClass == undefined )
      || (m.SubMarket == null || m.SubMarket == undefined ) )
    {
      invalidObject.push(m);
    }
    });
    if (this.state.breakCondition == false) {
      //let market = formattedData[0]["CoStar Market"];
      this.updateHeaderColumn(headers.sort());
      this.setState({
        importedFileData: formattedData,
        invalidObject:invalidObject,
        uKey: uploadingKey,
        uFile: uploadedFileName,
        openConfirmDialog: true,
//        importMarket: market,
        //openBulkUploadDialog:true,
      });
    }
    
    //console.log('import data',invalidObject);
//this.processFileImportAfterConfirm();
  };
  handleHeaderCheck(val) {
    //return this.state.listOfHeader.some((item) => item === val);
    return this.state.listOfHeader.includes(val);
  }
  onClickHandelFileImport(e) {
    if (e == true) {
      this.processFileImportAfterConfirm();
      this.setState({
        openConfirmDialog: false,
      });
    } else {
      this.setState({
        openConfirmDialog: false,
      });

      setDownloadingStatus(
        this.state.uKey,
        this.state.uFile,
        "failed",
        "",
        "Import cancelled by user"
      );
    }
  }
  processFileImportAfterConfirm = () => {
    let formattedData = this.state.importedFileData;
    let uploadingKey = this.state.uKey;
    let uploadedFileName = this.state.uFile;
    let ValidObj = [];
    let errorMessage = "";
    let isColumnMissing = false;

    let count = this.state.listOfCompColumns.length;
    let isColumnPresent = true;
    let columnNotFound = null;
    for (var i = 0; i < count; i++) {
      if (this.state.breakCondition != true && isColumnMissing != true)
        isColumnPresent = this.handleHeaderCheck(
          this.state.listOfCompColumns[i]
        );
      if (isColumnPresent == false) {
        isColumnMissing = true;
        columnNotFound =
          columnNotFound != null
            ? columnNotFound + "," + this.state.listOfCompColumns[i]
            : this.state.listOfCompColumns[i];

        errorMessage =
          "The import file does not contain columns specifiying the following data: [" +
          columnNotFound +
          "].Please re-import the file with this column included";
       
      }
    }
    if (isColumnMissing == true) {
      setDownloadingStatus(
        uploadingKey,
        uploadedFileName,
        "failed",
        errorMessage,
        "Validation Failure"
      );
    }
    if (isColumnMissing != true) {
      formattedData.forEach((obj) => {
        if (this.state.breakCondition == false && isColumnMissing == false) {
          let message = null;
          let extraColumn = null;
          for (var i = 0; i < this.state.listOfDbColumn.length; i++) {
            let oldColName = this.state.listOfHeader[i];
            let newColName = this.getDbColumn(this.state.listOfHeader[i]);
            if (newColName == null) {
              isColumnMissing = true;
              extraColumn =
                extraColumn != null
                  ? extraColumn + "," + this.state.listOfHeader[i]
                  : this.state.listOfHeader[i];
              errorMessage =
                "There is extra column in template [" +
                extraColumn +
                "].Please remove this column and import again";
              setDownloadingStatus(
                uploadingKey,
                uploadedFileName,
                "failed",
                errorMessage,
                "Validation Failure"
              );
              
            }
            renameKey(obj, oldColName, newColName);
             let returnObj = checkFileValidation(
              obj[this.state.listOfDbColumn[i]],
              this.state.getImportfileData[i].dataFormat
            );

            if (returnObj === false) {
              let typeError = null;
              if (
                this.state.getImportfileData[i].dataFormat === "MoneyNumber" ||
                this.state.getImportfileData[i].dataFormat === "Percent" ||
                this.state.getImportfileData[i].dataFormat === "DecimalPercent"
              ) {
                typeError =
                  ". Expected value is Number with maximum 2 decimal digits";
              } else if (
                this.state.getImportfileData[i].dataFormat === "Number"
              ) {
                typeError = ". Expected value is Number without decimal";
              } else if (
                this.state.getImportfileData[i].dataFormat === "Date"
              ) {
                typeError = ". Expected value is 'mm/dd/yyyy'";
              }
              else if (
                this.state.getImportfileData[i].dataFormat === "Checkbox"
              ) {
                typeError = ". Expected value is 'Yes Or No'";
              }
              else {
                typeError = "";
              }
              message =
                message != null
                  ? message +
                    " ,Data type error in [" +
                    this.state.getImportfileData[i].uiColumn +
                    "]" +
                    typeError
                  : "Data type error in [" +
                    this.state.getImportfileData[i].uiColumn +
                    "]" +
                    typeError;
              
            }   
            
            else{
              if (
                this.state.getImportfileData[i].dataFormat === "Checkbox" 
                && obj[this.state.getImportfileData[i].attributeName] != null && obj[this.state.getImportfileData[i].attributeName] != undefined
                && (obj[this.state.getImportfileData[i].attributeName]).toLowerCase() === "yes"
              ) {
            obj[this.state.getImportfileData[i].attributeName]="1";
              }
              else if (
                this.state.getImportfileData[i].dataFormat === "Checkbox" 
                && obj[this.state.getImportfileData[i].attributeName] != null && obj[this.state.getImportfileData[i].attributeName] != undefined
                && (obj[this.state.getImportfileData[i].attributeName]).toLowerCase() === "no"
              ) {
            obj[this.state.getImportfileData[i].attributeName]="0";
              }
              else if (
                this.state.getImportfileData[i].dataFormat === "Checkbox" 
                && obj[this.state.getImportfileData[i].attributeName] != null && obj[this.state.getImportfileData[i].attributeName] != undefined
                && (
                  (obj[this.state.getImportfileData[i].attributeName]).toLowerCase() === null || (obj[this.state.getImportfileData[i].attributeName]).toLowerCase() === undefined)
              ) {
            obj[this.state.getImportfileData[i].attributeName]=null;
              }
          }

}

          for (var i = 0; i < this.state.lstRequiredField.length; i++) {
            if (
              obj[this.state.lstRequiredField[i]] == null &&
              this.state.breakCondition == false
            ) {
              message =
                message != null
                  ? message +
                    " ,Field is required [" +
                    this.getUIColumn(this.state.lstRequiredField[i]) +
                    "]"
                  : "Field is required [" +
                    this.getUIColumn(this.state.lstRequiredField[i]) +
                    "]";
            }
          }
          obj.Comments = message;

        }
        
      });
    }
    let ValidData = formattedData.filter((m) => {
      if (m.Comments == null) return m;
    });
    let invalidData = formattedData.filter((m) => {
      if (m.Comments != null) return m;
    });

    invalidData.forEach((obj) => {
      for (var i = 0; i < this.state.listOfDbColumn.length; i++) {
        let oldColName = this.getDbColumn(this.state.listOfHeader[i]);
        let newColName = this.state.listOfHeader[i];
        renameKey(obj, oldColName, newColName);
      }
    });
    this.setState({
      formattedData: ValidData,
      uKey: uploadingKey,
      uFile: uploadedFileName,
      validFormattedData: ValidObj,
      invalidFormattedData: invalidData,
      invalidRecordCount: invalidData.length,
      totalRecordCount: formattedData.length,
    });
    if (this.state.breakCondition == false && isColumnMissing == false)
    this.getSpsData(true);
    else {
      errorMessage =
        errorMessage != null
          ? errorMessage
          : "Error while processing file. Please try again";
      setDownloadingStatus(
        uploadingKey,
        uploadedFileName,
        "failed",
        errorMessage,
        "Validation Failure"
      );
    }
  };
  downloadImportFileData(importData, fileName) {
    const wb = XLSX.utils.book_new();
    let compHeader = JSON.parse(
      JSON.stringify([...this.state.listOfCompColumns])
    );
    compHeader.push("Comments");
    importData.map((obj) => {
      orderKey(obj, compHeader);
    });
    let fN = fileName.split(".")[0];
    let ext = fileName.split(".")[1];
    let newFileName = fN + "_InvalidRecords." + ext;

    var data = importData;
    var wsData = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, wsData, "Validation Error");
    XLSX.writeFile(wb, newFileName);
    this.uploadInvalidRecords(wb, newFileName);
  }
  uploadInvalidRecords(wb, newFileName) {
    var bin = XLSX.write(wb, { type: "array", bookType: "xlsx" });
    var blob = new Blob([bin], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    var file = new File([blob], newFileName, {
      lastModified: new Date().getTime(),
    });
    // console.log('downloadfile',XLSX.readFile(fileName))
    this.setState(
      {
        selectedFile: file,
        selectedFileName: newFileName,
        selectedFileSize: file.size,
      },
      this.getSpsData(false)
    );
  }
  getSpsData = (callSaveImport) => {
    let env = getCurrentEnvironment().sharePointLibrary;
    if (getSPSTokenData()) {
      this.setState({ spsData: {} });
      getSpsAPiRequest(getSharePointUrl("streamrealty", "getFolder")).then(
        (spsDataRes) => {
          //            console.log(res);
          //if (res.value !== null && res.value.length > 0 &&
          //	(res.value.find(x => x.displayName === env || x.name === env) !== undefined)) {
          //		const parentFolder = res.value.find(x => x.displayName === env || x.name === env);
          //		const folderKey = parentFolder.id;
          //		getSpsAPiRequest(
          //			getSharePointUrl(
          //		  	'streamrealty',
          //		  	'getInnerFolder',
          //		  	folderKey,
          //			'Lease'
          //			)
          //	  	).then((spsDataRes) => {
          const fileName = this.state.selectedFileName;
          const folderName = env; //this.cleanName(fileName);
          if (
            spsDataRes.value !== null &&
            spsDataRes.value.length > 0 &&
            spsDataRes.value.find(
              (x) => x.displayName === folderName || x.name === folderName
            ) !== undefined
          ) {
            const folder = spsDataRes.value.find(
              (x) => x.displayName === folderName || x.name === folderName
            );
            const driveId = folder.parentReference.driveId;
            const key = folder.id;
            this.setState({ spListkey: key });
            this.uploadFilesToSharePoint(callSaveImport);
          } else {
            if (folderName.length > 0) {
              spsApiPost({
                url: getSharePointUrl("streamrealty", "createFolder"),
                postBody: {
                  displayName: folderName,
                  columns: [
                    {
                      name: "FileType",
                      text: {},
                    },
                    {
                      name: "UserEmail",
                      text: {},
                    },
                    {
                      name: "UserEmail",
                      text: {},
                    },
                  ],
                  list: {
                    template: "documentLibrary",
                  },
                },
              }).then((data) => {
                if (data) {
                  this.getSpsData(callSaveImport);
                }
              });
            }
          }
          //});
          //}
        }
      );
    } else {
      apiRequest({ url: API_URL.powerBi.AcquireSPToken }).then(({ data }) => {
        if (data) {
          setSPSToken(data);
          this.getSpsData(callSaveImport);
        }
      });
    }
  };

  uploadFilesToSharePoint(callSaveImport) {
    //        this.state.filesToBeUploaded.forEach(x => {
    let d = new Date();
    let dformat = `${
      d.getMonth() + 1
    }${d.getDate()}${d.getFullYear()}_${d.getHours()}${d.getMinutes()}${d.getSeconds()}`;
    let fileName = this.state.selectedFileName.split(".")[0];
    let ext = this.state.selectedFileName.split(".")[1];
    fileName = fileName + "_" + dformat + "." + ext;
    spsApiPost({
      url: getSharePointUrl(
        "streamrealty",
        "uploadFileSession",
        this.state.spListkey,
        fileName,
        "LeaseComps"
        //              x.category + '_Document_' + this.cleanName(getSectionAttributeValueByKey(this.state.getBuildingDetails, 'coStarPropId')) + '.pdf'
      ),
      postBody: {
        fileSize: this.state.selectedFileSize, //x.file.size,
        item: {
          "@microsoft.graph.conflictBehavior": "replace",
        },
      },
    }).then((data) => {
      if (data) {
        uploadSpsAPiRequest(
          data.data.uploadUrl,
          this.state.selectedFile,
          true
        ).then((data) => {
          if (data) {
            ShowToast("File uploaded successfully.", toastType.SUCCESS);
            this.setState({
              selectedFile: null,
              downloadFileUrl: data["@content.downloadUrl"],
            });
            //console.log(this.state.downloadFileUrl);
            if (
              this.state.breakCondition == false &&
              this.state.downloadFileUrl != null
            ) {
              this.saveImport(
                callSaveImport && this.state.formattedData,
                this.state.uKey,
                this.state.uFile,
                fileName
              );
            }
            //                  this.getList(data.name,
            //                    (((store.getState() || {}).auth || {}).account || {}).userName || '');

            //this.updateMetaData(data.id, (((store.getState() || {}).auth || {}).account || {}).userName || '', 'LeaseComps');
          } else {
            let errorMessage =
              "The import file could not be uploaded to the server.";
            setDownloadingStatus(
              this.state.uKey,
              this.state.uFile,
              "failed",
              errorMessage,
              "Processing Failure"
            );
          }

          //                this.props.onViewChange();
        });
      }
    });
    //        });
  }

  getList = (fileName, emailId) => {
    getSpsAPiRequest(
      getSharePointUrl("streamrealty", "getSps", this.state.spListkey)
    ).then((spsDataRes) => {
      if (spsDataRes) {
        const { value } = spsDataRes;
        for (let i = 0; i < value.length; i++) {
          if (fileName === value[i].name) {
            this.updateMetaData(value[i].listItem.id, emailId);
            break;
          }
        }
      }
    });
  };

  updateMetaData = (itemid, emailId = "", category) => {
    const metadata = {
      FileType: category,
      UserEmail: emailId,
    };
    uploadSpsMetadataAPiRequest(
      getSharePointUrl(
        "streamrealty",
        "uploadMetadata",
        this.state.spListkey,
        itemid
      ),
      metadata
    ).then(() => {
      // this.setState({ filesToUpload: [], selectedFile: [] });
      // this.getSpsData();
    });
  };

  getBase64Content = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      //          console.log('Error: ', error);
    };
  };

  viewFileData = async (obj, title) => {
    let url = "";
    if (obj.uploadToSp) {
      const { filesToBeUploaded, spsData } = this.state;
      const file = (filesToBeUploaded || []).find(
        (x) => x.category === obj.category
      );
      if (file && file.file) {
        this.getBase64Content(file.file, (result) => {
          //              console.log(result);
          url = result;
          this.showFile(url, title);
        });
      } else if (
        spsData &&
        spsData[obj.category] &&
        spsData[obj.category].length > 0
      ) {
        const spFile = _.orderBy(
          spsData[obj.category].filter((x) => x.name === title) || [],
          ["createdDateTime"],
          ["desc"]
        );
        if (spFile && spFile[0]) {
          this.showFile(
            await this.getSpBlob(spFile[0]["@microsoft.graph.downloadUrl"]),
            title,
            true
          );

          // this.generateShareLinkAndShow(spFile);
        } else {
          ShowToast("File not found!", toastType.INFO, 2000, 2000);
        }
      } else {
        ShowToast("File not found!", toastType.INFO, 2000, 2000);
      }
    } else {
      url = getSectionAttributeValueByKey(
        this.state.getBuildingDetails,
        obj.contentKey
      );
      this.showFile(url, title);
    }
  };

  getSpBlob = (url) => {
    return new Promise(function (resolve, reject) {
      fetch(url)
        .then((response) => response.blob())
        .then(function (blob) {
          const dataUrl = URL.createObjectURL(blob);
          return resolve(dataUrl);
        })
        .catch(() => {
          return resolve("");
        });
    });
  };

  b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  showFile = (url, title, isSpsFile = false) => {
    const contentType = "application/pdf";
    let blobUrl;
    if (isSpsFile === false) {
      const blob = this.b64toBlob(
        url.replace("data:application/pdf;base64,", ""),
        contentType
      );
      blobUrl = URL.createObjectURL(blob);
    } else {
      blobUrl = url;
    }
    this.setState({
      openaddform: true,
      addformtitle: title,
      addNewformComponent: (
        <div style={{ width: "800px", height: "530px", overflow: "hidden" }}>
          {/* <iframe src={url} width="700" height="700"></iframe> */}
          {/* <embed src={`data:application/pdf;base64,${url}`} type="application/pdf" width="100%"></embed> */}
          <iframe
            id="iframe"
            onLoad={(ev) => {
              const x = document.getElementById("iframe");
              (
                ((x.contentWindow || {}).document.getElementsByTagName(
                  "embed"
                ) || [])[0] || {}
              ).style.height = "500px";
            }}
            src={blobUrl}
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              display: "block",
            }}
            frameBorder="0"
            allowFullScreen={true}
          ></iframe>
        </div>
      ),
    });
  };

  saveImport = (formattedData, uploadingKey, uploadedFileName, spsFileName) => {   
    formattedData[formattedData.length] = this.state.downloadFileUrl;
    formattedData[formattedData.length] = spsFileName;
    apiPostNoLoader({
      url: API_URL.ImportExport.updateBulkPropertyIndustrial,
      params: {
        marketId: this.state.selectedValueId,
        invalidRecordCount: this.state.invalidRecordCount,
          },
      postBody: formattedData,
    })
      .then(({ data: response }) => {
        if (response.success === true) {
          this.setState({
            selectedFile: null,            
            fileData: [],
            userFileData: [],
            importMarket:null,           
          });
          let importedData = [];
          importedData = response.data;

          if (this.state.invalidFormattedData.length > 0) {
            this.state.invalidFormattedData.forEach((m) => {
              if (m != undefined) {
                importedData.push(m);
              }
            });
            
          }
          importedData.forEach((m)=>{
            if(m != undefined)
            {
            if (m["Keep Market Stats"] == "1" || m["Keep Market Stats"] == 1)
            m["Keep Market Stats"]="Yes";  
             if(m["Keep Market Stats"] == "0" || m["Keep Market Stats"] == 0)
            m["Keep Market Stats"]="No";
             if(m["Properties Of Interest"] =="1" || m["Properties Of Interest"] == 1)
            m["Properties Of Interest"]="Yes";
             if(m["Properties Of Interest"] =="0" || m["Properties Of Interest"] == 0)
            m["Properties Of Interest"]="No";
            }
          });
          let validCount = this.state.totalRecordCount - importedData.length;
          let message =
            "Total Records : " +
            this.state.totalRecordCount +
            " \nInvalid Records : " +
            importedData.length +
            " \nValid Records : " +
            validCount;
          setDownloadingStatus(
            uploadingKey,
            uploadedFileName,
            "completed",
            message,
            "Import Complete"
          );
          importedData.length > 0
            ? this.downloadImportFileData(importedData, uploadedFileName)
            : null;
          this.reloadGridData();
        } else {
          setDownloadingStatus(
            uploadingKey,
            uploadedFileName,
            "failed",
            response.message,
            "Import Failed"
          );
        }
      })
      .catch(() => {
        setDownloadingStatus(
          uploadingKey,
          uploadedFileName,
          "failed",
          "",
          "Import Failed"
        );
      });
  };
  handleRequiredFieldCheck(val) {
    //return this.state.listOfHeader.some((item) => item === val);
    return this.state.lstRequiredField.includes(val);
  }

  getDbColumn(val) {
    let dbColumn = this.state.getImportfileData.find((x) => x.uiColumn == val);
    let dbColumnName =
      dbColumn != undefined && dbColumn != null ? dbColumn.dbColumn : null;
    // dbColumnName == null ? this.setState({breakCondition : true}) : null;
    //return this.state.listOfHeader.some((item) => item === val);
    return dbColumnName;
  }
  getUIColumn = (val) => {
    return this.state.getImportfileData.find((x) => x.dbColumn == val).uiColumn;
    //return this.state.listOfHeader.some((item) => item === val);
  };
  updateHeaderColumn = (headers) => {
    this.setState({
      listOfHeader: headers.sort(),
    });
  };
  reloadPage = (page,rowcount) => {
    page = page;
    
    this.setState({pageNumber: page},
      () => {
        this.forceUpdate();
        this.reloadGridData(this.state.selectedMarketId);
      });
    this.setState({rowsPerPage: rowcount});
  };

  reloadPoiData = (page,rowcount) => {
    page = page;
    
    this.setState({poiPageNumber: page},
      () => {
        this.forceUpdate();
        this.onPaginatePOIClick();
      });
    this.setState({rowsPerPage: rowcount});
  };

  onPaginatePOIClick = () => {
    //if(this.state.propertyOfNonInterest.length === 0){
     
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialBuilding.industrialGetPropertyOfNonInterest,
        key: "propertyOfNonInterest",
        params: { marketId: this.state.selectedMarketId, 
          searchText: this.state.searchInputPopup,
          pageNumber: this.state.poiPageNumber + 1,
          pageSize: this.state.rowsPerPage  
        },
      },
    ])
      .then((propertyOfNonInterest) => {
        this._asyncRequest = null;
        if (propertyOfNonInterest.propertyOfNonInterest.success == true) {
          this.setState(
            {
              propertyOfNonInterest:
                camelcaseKeys(
                  propertyOfNonInterest.propertyOfNonInterest.data
                ) || [],
              responsePropertyOfNonInterest: _.cloneDeep(
                camelcaseKeys(
                  propertyOfNonInterest.propertyOfNonInterest.data
                ) || []
              ),
            },
            () => {
              this.forceUpdate();
            }
          );
        } else {
          ShowToast(
            propertyOfNonInterest.propertyOfNonInterest.message,
            toastType.error,
            5000,
            500
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });

    this.setState({
      openaddform: true,
      selectedBuilding: [],
      //searchInputPopup: "",
    });
  };

  render() {
    const {
      buildingSummaryData,
      buildingMasterData,
      companyMasterDetails,
    //  propertyFirmMaster,
      getSubMarketMaster,
      getSprinklerMaster,
      getBuildingMaster,
      getMarketMaster,
      openFilter,
      searchInput,
      userConfiguration,
      openSlidingPanel,
      listOfColumns,
      searchInputPopup,
      isConfirmPopupOpen,
      getSubmarketClusterMaster
    } = this.state;
    let pageSize = 0;
    const filterConfig = [
      {
        multiple: false,
        type: controlType.DropDown,
        minTag: 1,
        source: getMarketMaster,
        key: "market",
        placeholder: "",
        title: "Market",
      },
    ];
    let userConfig = userConfiguration.filter((m) => {
      if (m.sectionId == 11 && m.isVisible == true) {
        if (m.dataFormat == "MultiText") {
          m.minTag = 1;
          m.tagCount = true;
        }
        if (m.dataFormat == "Number") {
          m.headcellClass = "headcell-number-text";
        }
        if (m.key == "buildingName") {
          m.renderComponent = "renderActionColumn";
          // this.setState({
          pageSize = m.rowPerPage;
          //reloadTable:!this.state.reloadTable,
          //});
          return m;
        }
        return m;
      }
      //this.refs.gridTable.setRowsPerPage(pageSize);
    });
    userConfig.sort((a, b) =>
      a.attributeSortOrder > b.attributeSortOrder ? 1 : -1
    );
    const dbColumnList = userConfiguration.filter((m) => {
      if (m.sectionId == 11 && m.attributeSortOrder != -1 && !m.renderComponent)
        return m; //
    });
    dbColumnList.sort((a, b) =>
      a.attributeSortOrder > b.attributeSortOrder ? 1 : -1
    );

    buildingSummaryData.forEach((item, index) => {
      item.serial = index + 1;
    });
    return (
      <>
        {this.state.isConfirmPopupOpen && (
          <ConfirmDialog
            IsOpen={isConfirmPopupOpen}
            Message={this.state.confirmationMessage}
            CheckBoxMessage={"Set Keep Market Stats"}
            OnChange={(e) => {
              this.onChangeKeepMarketStats(e);
            }}
            OnHandelClick={(isValid) => {
              if (isValid) {
                this.onSaveChange(isValid);
              } else {
                this.onSaveChange(isValid);
              }
            }}
            AgreeText="Yes"
            DisAgreeText="No"
          />
        )}
        {this.state.detailView ? (
          <>
            <EditBuilding
              globalMarketId={this.props.globalMarketId}
              selectedBuildingId={this.state.selectedEditRowId}
              onBackClick={this.onBackClick}
              ViewTitle={this.state.ViewTitle}
              actionType={this.state.actionType}
              isInAddMode={this.state.isInAddMode}
              reloadData={this.reloadData}
              getMarketMaster={this.state.getMarketMaster}
              getSubMarketMaster={this.state.getSubMarketMaster}
              getSprinklerData={this.state.getSprinklerMaster}
              buildingMasterData={this.state.buildingMasterData}
              getSubmarketClusterMaster={getSubmarketClusterMaster}
            //  getPropertyFirmMaster={this.state.propertyFirmMaster}
              onCancelClickAfterPopupAdd={this.onCancelClickAfterPopupAdd}
            />
          </>
        ) : (
          <>
            <div className="row no-print">
              <div className="col-md-3 col-lg-3">
                <PageHeader title="Properties"></PageHeader>
                {/* <Button
                  aria-label={"addnewrow"}
                  className="action-button"
                  size="small"
                  onClick={() => {
                    this.setState({
                      isInAddMode: true,
                      selectedEditRowId:-1,
                    });
                    this.onAddNewClick();
                  }}
                >
                  <span className="tool-icon-text">Add New Building</span>
                </Button> */}
              </div>
              <div>
                {dbColumnList.length > 0 && (
                  <SettingScreenDialog
                    filterSetting={JSON.parse(
                      JSON.stringify([...dbColumnList])
                    )}
                    onRestoreToDefault={this.onRestoreToDefault}
                    onApplySetting={this.onSettingChange}
                    openSlidingPanel={openSlidingPanel}
                    handleClose={this.handleClose}
                  />
                )}
              </div>
              <div className="col-md-9 col-lg-9">
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <SearchBar                    
                      size="large"
                      name="searchInput"
                      searchInput={searchInput}
                      value={searchInput || ""}
                      onChange={this.handleChange}
                      label="Search"
                      placeholder="Click on icon to Search Company."
                      companyMasterDetails={this.state.propertyNameAddrCostar}
                      isOwner={true}
                      handleChange={this.handleSearchChange}
                    onInputChange={this.onSearchInputChange}
                    reloadData={this.resetAppliedFilter}
                    ></SearchBar>
                  </div>
                  <div className="col-md-3 col-lg-3"></div>
                  <div className="col-md-3 col-lg-3">
                    <ReportButtons
                      resetPage={[]}
                      openFilter={this.state.openFilter}
                      enableFilter={this.enableFilter}
                      handleSubmit={[]}
                      handleClickOpen={this.handleClickOpen}
                      handlePrintClick={this.handlePrintClick}
                      exportPDF={this.exportPDF}
                      data={buildingSummaryData}
                      columnWidths={columnWidths}
                      uploadColumns={uploadColumns}
                      fileName={"Report.xlsx"}
                      downloadExcelReport={this.downloadReport}
                      enableFilterButton={this.state.enableFilterButton}
                      ParentSection={SECTIONS.Industrial_Property}
                      ChildSectionForDownload={
                        SECTIONS.Industrial_Property_Download
                      }
                      ChildSectionForPrint={SECTIONS.Industrial_Property_Print}
                      ChildSectionForSettings={
                        SECTIONS.Industrial_Property_Settings
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <Divider className="divider-bg" orientation="vertical" flexItem /> */}
            {this.state.openDialog == true && (
                <Dialog open={this.state.openDialog} maxWidth="lg">
                  <AppBar
                    style={{ position: "relative", backgroundColor: "#064473" }}
                  >
                    <Toolbar>
                      <Typography
                        variant="subtitle1"
                        className="app-header-white"
                        style={{
                          marginLeft: "-11px",
                          flex: "1",
                          color: "#FFFFF",
                          marginTop: "-2px",
                        }}
                      >
                        Manage Data
                      </Typography>
                      <IconButton
                        edge="start"
                        color="inherit"
                        style={{ marginTop: "-18px", marginRight: "-30px" }}
                        onClick={() => {
                          this.setState({
                            openDialog: false,
                          });
                        }}
                        aria-label="close"
                      >
                        <IoMdCloseCircleOutline />
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                  <div>
                    <DownloadTemplate
                       importData={true}
                      downloadTemplate={this.downloadTemplate}
                      importFile={this.importFile}
                      enableClear={this.enableClear}
                      onCancel={this.onCancel}
                      exportData={this.exportData}
                      hideExportClearButton={true}
                      templateMessage={"Update Properties.Click"}
                    />
                  </div>
                </Dialog>
              )}
               {(this.state.openConfirmDialog == true ) && (
                <ConfirmDialog
                  IsOpen={
                    this.state.openConfirmDialog 
                  }
                  Message={
                   "You are updating Properties of Market [" +
                        this.state.selectedValue +
                        "]. \n\nDo you want to continue?"                      
                  }
                  OnHandelClick={(isValid) => {
                    if (isValid) {
                     this.onClickHandelFileImport(isValid);
                    } else { this.onClickHandelFileImport(isValid);
                    }
                  }}
                  AgreeText="Accept"
                  DisAgreeText="Cancel"
                ></ConfirmDialog>
              )}
                 {this.state.isUpload == true && (
                <Dialog open={this.state.isUpload} maxWidth="lg">
                  <AppBar
                    style={{
                      position: "relative",
                      backgroundColor: "#064473",
                      height: "38px",
                    }}
                  >
                    <div className="row">                     
                      <div
                        className="col-md-6 col-lg-6"
                        style={{ marginTop: "13px" }}
                      >
                        <span> Upload File</span>
                      </div>
                      <div className="col-md-6 col-lg-6">
                        <IconButton
                          style={{
                            float: "right",
                            marginTop: "-6px",
                            marginRight: "-15px",
                          }}
                          edge="start"
                          color="inherit"
                          onClick={() => {
                            this.setState({
                              isUpload: false,
                            });
                          }}
                          aria-label="close"
                        >
                          <IoMdCloseCircleOutline />
                        </IconButton>
                      </div>
                    </div>
                  </AppBar>
                  <div style={{ padding: "8px" }}>
                    <div>
                      Import File template :{" "}
                      <span className="span-mandatory">*</span>
                    </div> 
                       
                    <Autocomplete
                      size="small"
                      multiple={filterConfig[0].isMultiple}
                      underlineStyle={{ display: "none" }}
                      limitTags={1}
                      options={(this.state.getMarketMaster || []).map(
                        (option) => option[filterConfig[0].key]
                      )}
                      autoHighlight
                      value={this.state.selectedValue}
                      key={"dropDown_filter_lease_import"}
                      id={"dropDown_filter_lease_import"}
                      onChange={(event, value) => {
                        this.setState({
                          selectedValue: value,
                          selectedValueId: this.state.getMarketMaster.find(
                            (m) => m.market == value
                          ).marketId,
                        });
                      }}
                      renderInput={(params) => (
                        <Box
                          sx={{ display: "flex", alignItems: "center" }}
                          className="filter-label"
                        >
                          <label htmlFor="filled-hidden-label-small">
                            Market <span className="span-mandatory">*</span>
                          </label>
                          <TextField
                            {...params}
                            fullWidth
                            className="autosearc-text"
                            hiddenLabel
                            variant="outlined"
                            margin="normal"
                            placeholder={this.props.placeholder}
                          />
                        </Box>
                      )}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "5px !important",
                      }}
                      className="filter-label"
                    >
                      <label htmlFor="filled-hidden-label-small">
                        Import File
                      </label>
                    </Box>
                    <input
                      type="file"
                      style={{ padding: "24px", backgroundColor: "lightgray" }}
                      onChange={this.onFileChange}
                    />
                  </div>
                  <div style={{}}>
                    <button
                      className="import-button"
                      onClick={this.onFileUpload}
                    >
                      Upload
                    </button>
                  </div>
                </Dialog>
              )}
            {this.state.openaddform == true && (
              <Dialog open={this.state.openaddform} maxWidth="lg">
                <AppBar
                  style={{ position: "relative", backgroundColor: "#064473" }}
                >
                  <Toolbar className="custom-toolbar">
                    <Typography
                      variant="subtitle1"
                      className="app-header-white"
                      style={{ marginTop: "15px", flex: "1", color: "#FFFFF" }}
                    >
                      {"Properties of Interest"}
                    </Typography>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => {
                        this.setState({
                          openaddform: false,
                        });
                      }}
                      aria-label="close"
                      style={{ padding: "0px" }}
                    >
                      <IoMdCloseCircleOutline />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <div className="row">
                  <div className="col-sm-2 col-md-3 col-lg-3"></div>
                  <div className="col-sm-4 col-md-4 col-lg-4">
                    <SearchBar
                      size="large"
                      name="searchInputPopup"
                      value={searchInputPopup || ""}
                      onChange={this.handlePopupChange}
                      label="Search"
                      placeholder="Search Property Name or Address."
                    ></SearchBar>
                  </div>
                  <div className="col-sm-2 col-md-2 col-lg-2 end-sm"></div>
                  <div className="col-sm-4 col-md-3 col-lg-3 end-sm">
                    <Button
                      size="small"
                      className="notop-action-button"
                      onClick={() => {
                        this.onPopupBackClick();
                      }}
                    >
                      <span className="tool-icon-text">Cancel</span>
                    </Button>
                    <Button
                      size="small"
                      className="notop-action-button"
                      onClick={() => {
                        this.confirmSave();
                      }}
                    >
                      <span className="tool-icon-text">Save</span>
                    </Button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-lg-12">
                    <PaginatedGridTable
                      validationMessage={[]}
                      rowCount={
                        this.state.rowsPerPage > 0 ? this.state.rowsPerPage : 20
                      }
                      config={industrialBuildingColumns}
                      columns={UpdateColumnWithConfiguration(
                        industrialBuildingColumns,
                        [],
                        //  this.state.configuration,
                        []
                      )}
                      reload={this.state.reloadTable}
                      data={this.state.propertyOfNonInterest}
                      headerData={this.state.headerData}
                      renderActionColumn={this.renderPropertyOfInterestColumn}
                      handleTableHeaderEditChange={
                        this.handleTableHeaderEditChange
                      }
                      reloadPaginatedData={this.reloadPoiData}
                      pageNumber={this.state.poiPageNumber}
                    ></PaginatedGridTable>
                  </div>
                </div>
              </Dialog>
            )}

            {buildingSummaryData && listOfColumns && (
              <div className="row" style={{ marginTop: "0px" }}>
                <div className="col-sm-12 col-lg-12">
                  <PageFilter
                    onInputChange={this.onInputChange}
                    resetFilters={this.resetFilters}
                    autoFilter={true}
                    openAppliedFilterPane={true}
                    filterSelectedData={this.state.filterSelectedData}
                    filterApplied={true}
                    filterAlwaysVisible={openFilter}
                    openFilter={true}
                    onFilterChanged={this.onFilterChange}
                    filterConfig={[
                      {
                        multiple: false,
                        type: controlType.Autofill,
                        source: [
                          ...new Map(
                            getBuildingMaster.map((item) => [
                              item["buildingName"],
                              item,
                            ])
                          ).values(),
                        ],
                        key: "buildingName",
                        placeholder: "",
                        title: "Property Name",
                        isVisible: this.handleCheck("buildingName"),
                      },
                      {
                        multiple: true,
                        type: controlType.DropDown,
                        source: [
                          ...new Map(
                            getSubMarketMaster.map((item) => [
                              item["subMarket"],
                              item,
                            ])
                          ).values(),
                        ],
                        key: "subMarket",
                        placeholder: "",
                        title: "Sub Market",
                        isVisible: this.handleCheck("subMarket"),
                      },
                      {
                        multiple: true,
                        type: controlType.DropDown,
                        source: getSprinklerMaster,
                        key: "sprinklerType",
                        placeholder: "",
                        title: "Sprinkler",
                        isVisible: this.handleCheck("sprinklerType"),
                      },
                      {
                        multiple: true,
                        type: controlType.DropDown,
                        source: buildingMasterData,
                        key: "buildingClass",
                        placeholder: "",
                        title: "Class",
                        isVisible: this.handleCheck("buildingClass"),
                      },
                      {
                        multiple: false,
                        type: controlType.Autofill,
						minTag: 1,
                        source: companyMasterDetails,
                        key: "companyName",
                        placeholder: "",
                        title: "Owner",
                        isVisible: this.handleCheck("companyName"),
                      },
                    ]}
                    defaultSelectedData={[]}
                    selectedData={[]}
                    gridColumn={4}
                  ></PageFilter>
                </div>
              </div>
            )}

            {buildingSummaryData && userConfig.length > 0 && (
              <div className="row" style={{ marginTop: "0px" }}>
                <div className="col-sm-12 col-lg-12">
                  <PaginatedGridTable
                    validationMessage={[]}
                    rowCount={
                      this.state.rowsPerPage > 0 ? this.state.rowsPerPage : 20
                    }
                    config={userConfig}
                    columns={UpdateColumnWithConfiguration(
                      userConfig,
                      this.state.configuration,
                      []
                    )}
                    reload={this.state.reloadTable}
                    data={buildingSummaryData}
                    renderActionColumn={this.renderActionColumn}
                    AddNewRecord={{
                      action: this.onAddNewClick,
                      title: "Add Property",
                      visible: IsSectionVisible(
                        SECTIONS.Industrial_Property,
                        SECTIONS.Industrial_Property_Listing,
                        SECTIONS.ADD_EDIT
                      ),
                    }}
                    ConvertToPOI={{
                      action: this.onConvertToPOIClick,
                      title: "Add Property of Interest",
                      visible: IsSectionVisible(
                        SECTIONS.Industrial_Property,
                        SECTIONS.Industrial_Property_Listing,
                        SECTIONS.ADD_EDIT
                      ),
                    }}
                    additionalAction={{
                      action: this.enableDownload,
                      title: "Update Properties",
                      visible: IsSectionVisible(
                        SECTIONS.Industrial_Property,
                        SECTIONS.Industrial_Property_Listing,
                        SECTIONS.ADD_EDIT
                      ),
                    }}
                    reloadPaginatedData={this.reloadPage}
                    pageNumber={this.state.pageNumber}

                  ></PaginatedGridTable>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}
